<template>
  <div id='app'>
    <header>
    </header>
    <main role='main'>
      <div>
        <!-- Display env info even when not dev -->
        <button class="btn btn-success my-2 my-sm-0" disabled v-if="environmentMenuBar==='local-standalone'"><i class="fas fa-vials"></i> Local Standalone</button>
        <button class="btn btn-primary my-2 my-sm-0" disabled v-if="environmentMenuBar==='local'"><i class="fas fa-flask"></i> Local</button>
        <button class="btn btn-primary my-2 my-sm-0" disabled v-if="environmentMenuBar==='UAT'"><i class="fas fa-flask"></i> UAT</button>
      </div>
      <router-view/>
    </main>
  </div>
</template>

<script>
import '@s/css/main.css'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: { 
    ...mapGetters(['environmentMenuBar']),
  },
  created() {
    this.routeChange();
  },
  watch: {
    $route: 'routeChange'
  },
  methods: {
    routeChange(){
      this.setTitle();
    },
    setTitle(){
      if(this.environmentMenuBar === 'local-standalone'){
        document.title =  "DEV - Brokit"
      }
    }
  }
}
</script>

<style>
</style>
